export default {
  //   订单管理
  // 订单列表
  order_list_credit_report: 'OrderCreditReport',
  order_list_delivery_detail: 'order_list_delivery_detail',

  // 资方管理
  // 资方列表
  capital_list_status: 'capital_list_status',
  capital_list_export: 'capital_list_export',
  capital_list_add: 'capital_list_add',
  capital_list_edit: 'capital_list_edit',
  capital_list_recharge: 'capital_list_recharge',
  // 动账明细
  capital_bill_list_export: 'capital_bill_list_export',
  // 资方提现
  capital_withdraw_list_export: 'capital_withdraw_list_export',
  capital_withdraw_list_review: 'capital_withdraw_list_review',

}
