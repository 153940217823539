<template>
  <div class="wrapper">
    <admin-title :title="$route.meta.name"></admin-title>
    <div class="header-search">
      <el-form ref="form" inline :model="searchData" label-width="">
        <el-row :gutter="10">
          <el-col :span="24">
            <el-form-item label="交易类型" class="bill-type">
              <el-radio-group v-model="searchData.amountType" size="mini" @input="getList">
                <el-radio-button :label="null">全部</el-radio-button>
                <el-radio-button :label="1">收入</el-radio-button>
                <el-radio-button :label="2">支出</el-radio-button>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="订单号">
          <el-input style="width:220px" v-model="searchData.orderNo" size="mini" placeholder="请输入" clearable></el-input>
        </el-form-item>

        <el-form-item label="动账类型">
          <SelectDict v-model="searchData.billType" size="mini" width="220px" dictCode="capital:financeBillType"></SelectDict>
        </el-form-item>
        <el-form-item label="创建日期">
          <el-date-picker
            style="width:220px"
            v-model="timeSlot"
            type="daterange"
            range-separator="-"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd"
            size="mini"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label=" ">
          <el-button type="primary" icon="el-icon-search" size="mini" @click="getList()">搜索</el-button>
          <el-button type="info" icon="el-icon-refresh" size="mini" @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-divider></el-divider>
    <div class="head-btn">
      <div></div>
      <el-button
        v-checkbtn="isShowBtn(AUTH_BTN.capital_bill_list_export)"
        type="primary"
        icon="el-icon-download"
        size="mini"
        @click="handleExport"
        >导出</el-button
      >
    </div>

    <el-table :data="list" style="width: 100%">
      <el-table-column type="index" label="序号" :index="indexMethod" width="100"> </el-table-column>
      <el-table-column prop="amountType" label="交易类型" min-width="100">
        <template slot-scope="{ row }">
          <el-tag v-if="row.amountType == 1" type="primary" size="small">{{ row.amountTypeName }}</el-tag>
          <el-tag v-if="row.amountType == 2" type="success" size="small">{{ row.amountTypeName }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="createTime" label="动账时间" min-width="160"> </el-table-column>
      <el-table-column prop="billTypeName" label="动账类型" min-width="120"> </el-table-column>
      <el-table-column prop="memberName" label="姓名" min-width="120"> </el-table-column>
      <el-table-column prop="orderNo" label="订单号" min-width="200" />
      <el-table-column prop="amount" label="交易金额" min-width="110" />
      <el-table-column prop="afterAvailableAmount" label="交易后余额" min-width="110" />
      <el-table-column prop="remark" label="备注" min-width="130"></el-table-column>
    </el-table>
    <pagination :total="total" :page.sync="searchData.page" :limit.sync="searchData.pageSize" @pagination="getList" />

    <div style="height:100px"></div>
  </div>
</template>

<script>
import { authBtnMixin } from '@/mixins/authBtnMixin'
import { getListAPI, DownloadAPI } from './api'
import SelectDict from '@/views/components/select-dict.vue'
export default {
  name: 'List',
  mixins: [authBtnMixin],
  components: { SelectDict },
  data() {
    return {
      total: 0,
      list: [],
      searchData: {
        page: 1,
        pageSize: 10,
        orderNo: '',
        leftCreateDate: '',
        rightCreateDate: '',
        amountType: null, //交易类型：1-收入 2-支出
        billType: ''
      },
      timeSlot: []
    }
  },

  mounted() {
    this.getList()
  },

  methods: {
    async getList() {
      const { timeSlot } = this
      let params = { ...this.searchData }
      if (timeSlot && timeSlot.length > 0) {
        params.leftCreateDate = timeSlot[0]
        params.rightCreateDate = timeSlot[1]
      }
      const res = await getListAPI(params)
      this.list = res.list || []
      this.total = res.total || 0
    },

    reset() {
      this.timeSlot = []
      Object.assign(this._data.searchData, this.$options.data().searchData)
      this.getList()
    },

    handleExport() {
      const { orderNo, amountType, billType } = this.searchData
      const { timeSlot } = this
      let params = { orderNo, amountType, billType }
      if (timeSlot && timeSlot.length > 0) {
        params.leftCreateDate = timeSlot[0]
        params.rightCreateDate = timeSlot[1]
      }
      DownloadAPI(params)
    },
    // 序号自增--方法
    indexMethod(index) {
      // 拿到页码
      const currentPage = this.searchData.page
      // 拿到每页记录条数
      const pageSize = this.searchData.pageSize
      // 根据值进行计算返回
      return index + 1 + (currentPage - 1) * pageSize
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper /deep/ {
  width: 100%;
  .el-dialog {
    .el-dialog__body {
      padding-top: 10px;
    }
  }
  .el-divider--horizontal {
    margin: 10px 0 20px;
  }
  .bill-type {
    .el-radio-button__inner {
      padding: 7px 24px;
    }
  }
  .head-btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  .el-form {
    .el-form-item {
      margin-right: 25px;
      margin-bottom: 0px;
    }
  }
  .captial-detail {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    padding-left: 20px;
    .row {
      display: flex;
      align-items: center;
      margin-right: 40px;
      .label {
        display: flex;
        align-items: center;
        i {
          margin-right: 5px;
        }
      }
      .value {
        font-weight: 600;
      }
    }
  }
}
</style>
<style lang="scss" scoped>
.wrapper /deep/ {
  /* switch按钮样式 */
  .switch .el-switch__label {
    position: absolute;
    display: none;
    color: #fff !important;
  }
  /*打开时文字位置设置*/
  .switch .el-switch__label--right {
    z-index: 1;
  }
  /* 调整打开时文字的显示位子 */
  .switch .el-switch__label--right span {
    margin-left: 5px;
    margin-right: 10px;
  }
  /*关闭时文字位置设置*/
  .switch .el-switch__label--left {
    z-index: 1;
  }
  /* 调整关闭时文字的显示位子 */
  .switch .el-switch__label--left span {
    margin-left: 20px;
  }
  /*显示文字*/
  .switch .el-switch__label.is-active {
    display: block;
  }
  /* 调整按钮的宽度 */
  .switch.el-switch .el-switch__core,
  .el-switch .el-switch__label {
    width: 55px !important;
    margin: 0;
  }
}
</style>
